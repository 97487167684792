export enum HelperSlugs {
  nhom_khach_hang = 'nhom_khach_hang',
  danh_sach_khach_hang = 'danh_sach_khach_hang',
  ulead = 'ulead',
  lich_su_giao_dich = 'lich_su_giao_dich',
  nap_tien = 'nap_tien',
  thong_bao = 'thong_bao',
  lich_su_cuoc_goi = 'lich_su_cuoc_goi',
  danh_cho_doi_tac = 'danh_cho_doi_tac',
  callbot_ai_auto_call = 'callbot_ai_auto_call',
  callbot_buoc_1 = 'callbot_buoc_1',
  callbot_buoc_2 = 'callbot_buoc_2',
  callbot_buoc_3 = 'callbot_buoc_3',
  callbot_buoc_4 = 'callbot_buoc_4',
  callbot_bao_cao_tong_quan = 'callbot_bao_cao_tong_quan',
  callbot_bao_cao_chi_tiet = 'callbot_bao_cao_chi_tiet',
  quay_so_telesales = 'quay_so_telesales',
  quay_so_buoc_1 = 'quay_so_buoc_1',
  quay_so_buoc_2 = 'quay_so_buoc_2',
  quay_so_buoc_3 = 'quay_so_buoc_3',
  quay_so_buoc_4 = 'quay_so_buoc_4',
  quay_so_bao_cao_tong_quan = 'quay_so_bao_cao_tong_quan',
  quay_so_bao_cao_chi_tiet = 'quay_so_bao_cao_chi_tiet',
}

export const routesPath = {
  not_found: '/404',
  home: '/',
  onboard: '/onboard',
  login: '/user/login',
  register: '/user/register',
  confirmation: '/confirmation',
  preview: '/preview',
  oazalo: '/oazalo',
  notification: (companyId: string) => `/company/${companyId}/notification`,
  refresh: '/refresh',
  wallet: (companyId: string) => {
    return {
      detail: `/company/${companyId}/wallet/`,
      payment: `/company/${companyId}/wallet/payment`,
      orderHistory: `/company/${companyId}/wallet/order-history`,
      orderHistoryNew: `/company/${companyId}/wallet/order-history`,
      transaction: `/company/${companyId}/wallet/transaction`,
      budget_limit: `/company/${companyId}/wallet/budget-limit`,
      blocks_history: (PRODUCT_ID: string) =>
        `/company/${companyId}/wallet/${PRODUCT_ID}/blocks-history`,
    };
  },
  deposit: (companyId: string) => {
    return {
      product: (PRODUCT_SLUG: string) => `/company/${companyId}/wallet/deposit/${PRODUCT_SLUG}`,
    };
  },
  company: (companyId: string) => `/company/${companyId}`,
  customers: (companyId: string) => {
    return {
      list: `/company/${companyId}/customers/`,
      listOfGroup: (CUSTOMER_GROUP_ID: string) =>
        `/company/${companyId}/customers#${CUSTOMER_GROUP_ID}`,
    };
  },
  scripts: (companyId: string, CALL_BOT_SCENARIO_ID: string) =>
    `/company/${companyId}/scripts/${CALL_BOT_SCENARIO_ID}/detail`,
  scriptsList: (companyId: string) => `/company/${companyId}/scripts`,
  agent_campaign: {
    list: (COMPANY_ID: string) => `/company/${COMPANY_ID}/campaigns/agents`,
    detail: (COMPANY_ID: string, AGENT_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/campaigns/agents/${AGENT_CAMPAIGN_ID}/detail`,
    report: (COMPANY_ID: string, AGENT_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/campaigns/agents/${AGENT_CAMPAIGN_ID}/report`,
    reportDetail: (COMPANY_ID: string, AGENT_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/campaigns/agents/${AGENT_CAMPAIGN_ID}/report-detail`,
    start: (COMPANY_ID: string, AGENT_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/campaigns/agents/${AGENT_CAMPAIGN_ID}/start`,
  },
  dial_campaign: {
    list: (COMPANY_ID: string) => `/company/${COMPANY_ID}/campaigns/dials`,
    detail: (COMPANY_ID: string, DIAL_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/campaigns/dials/${DIAL_CAMPAIGN_ID}/detail`,
    report: (COMPANY_ID: string, DIAL_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/campaigns/dials/${DIAL_CAMPAIGN_ID}/report`,
    reportDetail: (COMPANY_ID: string, DIAL_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/campaigns/dials/${DIAL_CAMPAIGN_ID}/report-detail`,
    start: (COMPANY_ID: string, DIAL_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/campaigns/dials/${DIAL_CAMPAIGN_ID}/start`,
  },
  call_campaign: {
    call: {
      list: (COMPANY_ID: string) => `/company/${COMPANY_ID}/campaigns/calls`,
      detail: (COMPANY_ID: string, CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/campaigns/calls/${CAMPAIGN_ID}/detail`,
      report: (COMPANY_ID: string, CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/campaigns/calls/${CAMPAIGN_ID}/report`,
      reportDetail: (COMPANY_ID: string, CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/campaigns/calls/${CAMPAIGN_ID}/report-detail`,
    },
  },
  sms_campaign: {
    report: (COMPANY_ID: string, SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/campaigns/messages/${SMS_CAMPAIGN_ID}/report`,
    detail: (COMPANY_ID: string, SMS_CAMPAIGN_ID: string) =>
      `/company/${COMPANY_ID}/campaigns/messages/${SMS_CAMPAIGN_ID}/detail`,
  },
  settings: (COMPANY_ID: string) => `/company/${COMPANY_ID}/settings_new`,
};

export const nestedRoutesPath = (companyId: string) => {
  const companyPath = `/company/${companyId}`;
  return {
    changePassword: companyPath + `/account/change-password`,
    transaction: (affiliateID: string) => companyPath + `/affiliate/${affiliateID}/transaction`,
  };
};

export default [
  {
    path: '/user',
    layout: false,
    routes: [
      {
        path: '/user',
        routes: [
          {
            name: 'login',
            path: '/user/login',
            component: './user/login',
          },
          {
            name: 'register',
            path: '/user/register',
            component: './user/register',
          },
        ],
      },
      {
        component: './404',
        layout: false,
      },
    ],
  },
  {
    path: '/preview/:encodedParams',
    layout: false,
    component: './preview',
  },
  {
    path: '/oazalo/',
    component: './oazalo',
    layout: false,
  },
  {
    path: '/',
    access: 'isLandingPage',
    name: 'UCALL - Tổng đài Callbot AI Auto Call',
    description:
      'Giải pháp auto call ứng dụng công nghệ AI (trí tuệ nhân tạo) giúp tối ưu chi phí, thời gian cho doanh nghiệp đồng thời đạt hiệu quả cao. Phần mềm auto call của UCALL sẽ giúp doanh nghiệp thuận tiện hơn trong việc tìm kiếm khách hàng, telesales, telemarketing và chăm sóc khách hàng',
    keywords:
      'giải pháp auto call, gọi tự động, voicebot, dịch vụ auto call, phần mềm auto call, tự động gọi, phần mềm auto gọi điện',
    component: './new/index',
    layout: false,
  },
  {
    path: '/solutions',
    name: 'UCALL - Giải pháp callbot AI auto call',
    access: 'isLandingPage',
    keywords:
      'dịch vụ auto call, dịch vụ gọi tự động, dich vu auto call, tổng đài auto call (tỉ lệ tăng trưởng cao)',
    description:
      'Tổng đài auto call do UCALL cung cấp sẽ giúp doanh nghiệp giảm thiểu chi phí và thời gian; đồng thời dịch vụ auto call cũng sẽ giúp doanh nghiệp tìm kiếm data khách hàng, telesales, telemarketing và chăm sóc khách hàng tiết kiệm thời gian, chi phí nhưng vẫn đảm bảo hiệu quả.',
    layout: false,
    routes: [
      {
        path: 'telemarketing-telesale-tu-dong',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Telemarketing, Telesale tự động',
        component: './new/solutions/telemarketing-telesale-tu-dong',
        layout: false,
      },
      {
        path: 'telemarketing-telesale/ban-le',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Bán lẻ',
        component: './new/solutions/telemarketing-telesale/ban-le',
        layout: false,
      },
      {
        path: 'telemarketing-telesale/bao-hiem',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Bảo hiểm',
        component: './new/solutions/telemarketing-telesale/bao-hiem',
        layout: false,
      },
      {
        path: 'telemarketing-telesale/bat-dong-san',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Bảo hiểm',
        component: './new/solutions/telemarketing-telesale/bat-dong-san',
        layout: false,
      },
      {
        path: 'telemarketing-telesale/giao-duc',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Giáo dục',
        component: './new/solutions/telemarketing-telesale/giao-duc',
        layout: false,
      },
      {
        path: 'telemarketing-telesale/spa',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Spa',
        component: './new/solutions/telemarketing-telesale/spa',
        layout: false,
      },
      {
        path: 'telemarketing-telesale/tai-chinh',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Tài chính',
        component: './new/solutions/telemarketing-telesale/tai-chinh',
        layout: false,
      },
      {
        path: 'cham-soc-khach-hang-tu-dong',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Chăm sóc khách hàng tự động',
        component: './new/solutions/cham-soc-khach-hang-tu-dong',
        layout: false,
      },
      {
        path: 'cham-soc-khach-hang/chot-don-tu-dong',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Chốt đơn tự động',
        component: './new/solutions/cham-soc-khach-hang/chot-don-tu-dong',
        layout: false,
      },
      {
        path: 'cham-soc-khach-hang/khao-sat-thi-truong',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Khảo sát thị trường',
        component: './new/solutions/cham-soc-khach-hang/khao-sat-thi-truong',
        layout: false,
      },
      {
        path: 'cham-soc-khach-hang/lay-feedback-khach-hang',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Lấy feedback khách hàng',
        component: './new/solutions/cham-soc-khach-hang/lay-feedback-khach-hang',
        layout: false,
      },
      {
        path: 'cham-soc-khach-hang/thong-bao-chuong-trinh',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Thông báo chương trình',
        component: './new/solutions/cham-soc-khach-hang/thong-bao-chuong-trinh',
        layout: false,
      },
      {
        path: 'cham-soc-khach-hang/nhac-lich-tu-dong',
        access: 'isLandingPage',
        name: 'UCALL - Giải pháp Callbot Nhắc lịch tự động',
        component: './new/solutions/cham-soc-khach-hang/nhac-lich-tu-dong',
        layout: false,
      },
    ],
  },

  {
    path: '/products',
    name: 'UCALL - Giải pháp callbot AI auto call',
    access: 'isLandingPage',
    keywords:
      'dịch vụ auto call, dịch vụ gọi tự động, dich vu auto call, tổng đài auto call (tỉ lệ tăng trưởng cao)',
    description:
      'Tổng đài auto call do UCALL cung cấp sẽ giúp doanh nghiệp giảm thiểu chi phí và thời gian; đồng thời dịch vụ auto call cũng sẽ giúp doanh nghiệp tìm kiếm data khách hàng, telesales, telemarketing và chăm sóc khách hàng tiết kiệm thời gian, chi phí nhưng vẫn đảm bảo hiệu quả.',
    layout: false,
    routes: [
      {
        path: 'callbot-ai-auto-call',
        name: 'UCALL - Giải pháp Callbot AI gọi tự động',
        component: './new/products/callbot-ai-auto-call',
        layout: false,
      },
      {
        path: 'tong-dai-telesale',
        name: 'UCALL - Tổng đài telesale',
        component: './new/products/tong-dai-telesale',
        layout: false,
      },
      {
        path: 'ulead',
        name: 'UCALL - Giải pháp Tìm kiếm khách hàng Ulead',
        component: './new/products/ulead',
        layout: false,
      },
    ],
  },

  {
    path: '/pricing',
    name: 'UCALL - Giá và gói bán',
    description:
      'UCALL gửi đến khách hàng và doanh nghiệp bảng giá và chi phí sử dụng giải pháp callbot AI auto call của chúng tôi nhằm giúp khách hàng dễ dàng hơn trong việc cân nhắc và lựa chọn gói dịch vụ auto call phù hợp cho doanh nghiệp của mình.',
    access: 'isLandingPage',
    component: './new/pricing',
    layout: false,
  },
  {
    path: '/help',
    name: 'UCALL - Hướng dẫn sử dụng',
    description:
      'Hướng dẫn sử dụng Giải pháp AI auto call của UCALL giúp bạn dễ dàng tạo ra những chiến dịch gọi tự động và sử dụng phần mềm auto call được dễ dàng và hiệu quả hơn.',
    access: 'isLandingPage',
    component: './help',
    layout: false,
  },
  {
    path: '/contact',
    name: "UCALL - Liên hệ'",
    access: 'isLandingPage',
    component: './contact',
    layout: false,
  },
  {
    path: '/order',
    name: "UCALL - Đặt Hàng'",
    access: 'isLandingPage',
    component: './new/order',
    layout: false,
  },
  {
    path: '/payment',
    name: "UCALL - Thanh Toán'",
    access: 'isLandingPage',
    component: './new/payment',
    layout: false,
  },
  {
    path: '/p',
    layout: false,
    access: 'isLandingPage',
    routes: [
      { path: 'data', component: './p/data' },
      { path: 'terms', component: './p/terms' },
      { path: 'delivery', component: './p/delivery' },
      // { path: 'refund', component: './p/refund' },
      { path: 'payment', component: './p/payment' },
    ],
  },
  {
    path: '/confirmation',
    layout: false,
    component: './confirmation',
    isExact: true,
  },

  {
    path: '/onboard',
    layout: false,
    component: './onboard',
  },
  {
    path: '/company/:companyID',
    menu: {
      name: 'uCall',
      icon: '',
      flatMenu: true,
      hideInMenu: false,
      hideChildrenInMenu: false,
    },
    routes: [
      {
        path: './',
        component: './welcome',
      },
      {
        path: 'account',
        routes: [
          {
            name: 'Đổi Mật Khẩu',
            path: './change-password',
            component: './account/change-password',
          },
        ],
      },

      {
        path: 'partner',
        name: 'partner',
        icon: 'star',
        access: 'isPartner',
        component: './partner',
      },
      {
        path: 'customers',
        name: 'customers',
        icon: 'team',
        access: 'canAdmin',
        routes: [
          {
            path: './groups/',
            name: 'customer_groups',
            icon: 'phone',
            component: './customers/groups',
          },
          {
            path: './',
            name: 'customers_list',
            icon: 'phone',
            component: './customers',
          },
          {
            path: './imports/',
            name: 'uLeads',
            access: 'isDisplayUleads',
            icon: 'import',
            component: './customers/import',
          },
          {
            component: './404',
            layout: false,
          },
        ],
      },
      {
        path: 'scripts',
        name: 'scripts',
        icon: 'file',
        access: 'canAdmin',
        routes: [
          {
            path: './',
            name: 'scripts',
            icon: 'file',
            component: './scripts',
          },
          {
            path: './scripts/templates',
            name: 'templates',
            icon: 'file',
            access: 'isDev',
            component: './scripts/templates',
          },
          {
            path: './:CALL_BOT_SCENARIO_ID/detail',
            icon: 'file',
            component: './scripts/[CALL_BOT_SCENARIO_ID]/detail',
          },
          {
            component: './404',
            layout: false,
          },
        ],
      },
      {
        path: 'campaigns',
        name: 'campaigns',
        icon: 'notification',
        access: 'canAdmin',
        routes: [
          {
            path: 'calls',
            name: 'call_campaigns',
            icon: 'phone',
            component: './campaigns/calls',
          },
          {
            path: 'agents',
            name: 'agents',
            icon: 'phone',
            component: './campaigns/agents',
          },
          {
            path: 'dials',
            name: 'dials',
            icon: 'phone',
            component: './campaigns/dials',
          },
          {
            path: 'messages',
            name: 'messages',
            access: 'isDev',
            icon: 'phone',
            component: './campaigns/messages',
          },
          // {
          //   path: 'messages_v3',
          //   name: 'messages_v3',
          //   access: 'isDev',
          //   icon: 'phone',
          //   component: './v3/zaloV3',
          // },
          {
            path: 'dials/:DIAL_CAMPAIGN_ID/detail',
            icon: 'phone',
            component: './campaigns/dials/[DIAL_CAMPAIGN_ID]/detail',
          },
          {
            path: 'dials/:DIAL_CAMPAIGN_ID/start',
            component: './campaigns/dials/[DIAL_CAMPAIGN_ID]/start',
          },
          {
            path: 'dials/:DIAL_CAMPAIGN_ID/report',
            component: './campaigns/dials/[DIAL_CAMPAIGN_ID]/report',
          },
          {
            path: 'agents/:AGENT_CAMPAIGN_ID/detail',
            icon: 'phone',
            component: './campaigns/agents/[AGENT_CAMPAIGN_ID]/detail',
          },
          {
            path: 'agents/:AGENT_CAMPAIGN_ID/report-detail',
            component: './campaigns/agents/[AGENT_CAMPAIGN_ID]/report-detail',
          },
          {
            path: 'agents/:AGENT_CAMPAIGN_ID/report',
            component: './campaigns/agents/[AGENT_CAMPAIGN_ID]/report',
          },
          {
            path: 'agents/:AGENT_CAMPAIGN_ID/start',
            component: './campaigns/agents/[AGENT_CAMPAIGN_ID]/start',
          },
          {
            path: 'calls/:CAMPAIGN_CALL_ID/detail',
            icon: 'phone',
            component: './campaigns/calls/[CAMPAIGN_CALL_ID]/detail',
          },
          {
            path: 'calls/:CAMPAIGN_CALL_ID/report',
            icon: 'phone',
            component: './campaigns/calls/[CAMPAIGN_CALL_ID]/report',
          },
          {
            path: 'calls/:CAMPAIGN_CALL_ID/report-detail',
            component: './campaigns/calls/[CAMPAIGN_CALL_ID]/report-detail',
          },
          {
            path: 'messages/:SMS_CAMPAIGN_ID/report',
            icon: 'phone',
            component: './campaigns/messages/[SMS_CAMPAIGN_ID]/report',
          },
          {
            path: 'messages/:SMS_CAMPAIGN_ID/detail',
            icon: 'phone',
            component: './campaigns/messages/[SMS_CAMPAIGN_ID]/detail',
          },
          {
            component: './404',
            layout: false,
          },
        ],
      },
      {
        path: 'wallet',
        name: 'my_wallet',
        icon: 'wallet',
        routes: [
          // {
          //   path: './',
          //   name: 'transaction_history',
          //   icon: 'wallet',
          //   component: './wallet',
          // },
          // {
          //   path: './payment',
          //   name: 'topup',
          //   icon: 'wallet',
          //   component: './wallet/new-payment',
          // },

          {
            path: './deposit',
            name: 'topup',
            icon: 'file',
            component: './wallet/deposit',
          },
          {
            path: './deposit/:PRODUCT_SLUG',
            icon: 'file',
            component: './wallet/deposit/[PRODUCT_SLUG]/product',
          },
          // {
          //   path: './order-history',
          //   name: 'orderhistory',
          //   icon: 'wallet',
          //   component: './wallet/order-history',
          // },
          {
            path: './expense-report',
            name: 'expensereport',
            icon: 'wallet',
            component: './wallet/order-history-new',
          },

          {
            path: './order-history',
            name: 'orderhistorynew',
            icon: 'wallet',
            component: './wallet/order-history-new2',
          },
          {
            path: './transaction',
            icon: 'wallet',
            component: './wallet/transaction',
          },
          {
            path: './budget-limit',
            icon: 'wallet',
            component: './wallet/budget-limit',
          },

          {
            path: './:PRODUCT_ID/blocks-history',
            icon: 'wallet',
            component: './wallet/[PRODUCT_ID]/blocks-history',
          },
        ],
      },

      {
        path: 'notification',
        name: 'notification',
        icon: 'bell',
        access: 'canAdmin',
        component: './notification',
      },
      {
        path: 'caller',
        name: 'history',
        icon: 'phone',
        access: 'canAdmin',
        routes: [
          {
            path: './caller/history',
            name: 'calls_history',
            icon: 'wallet',
            component: './caller/history',
          },
        ],
      },
      {
        path: 'settings_new',
        name: 'new_settings',
        icon: 'setting',
        access: 'canAdmin',
        component: './settings/new',
      },
      // {
      //   path: 'settings_v3',
      //   name: 'new_settings_v3',
      //   icon: 'setting',
      //   access: 'canAdmin',
      //   component: './v3/settings',
      // },
      {
        path: 'help',
        name: 'help',
        icon: 'questionCircle',
        access: 'displayHelp',
        // access: 'canAdmin',
        component: './uhelp',
      },
      {
        path: 'affiliate',
        name: 'affiliate',
        icon: 'gift',
        access: 'displayHelp',
        routes: [
          {
            path: 'invite',
            name: 'invite',
            icon: 'star',
            access: 'displayHelp',
            component: './invite',
          },
          {
            path: 'customers',
            name: 'customers',
            access: 'displayHelp',
            icon: 'star',
            component: './affiliate/customers',
          },
        ],
      },
      // {
      //   path: 'invite',
      //   name: 'invite',
      //   icon: 'star',
      //   access: 'displayInvitePartner',
      //   component: './invite',
      // },
      // {
      //   name: 'list.table-list',
      //   icon: 'table',
      //   path: '/list',
      //   component: './TableList',
      // },
      // {
      //   path: '/admin',
      //   name: 'admin',
      //   icon: 'crown',
      //   access: 'canAdmin',
      //   component: './Admin',
      //   routes: [
      //     {
      //       path: '/admin/sub-page',
      //       name: 'sub-page',
      //       icon: 'smile',
      //       component: './Welcome',
      //     },
      //     {
      //       component: './404',
      //     },
      //   ],
      // },
    ],
  },
  {
    path: '/refresh',
    component: './refresh',
  },
  {
    component: './404',
    layout: false,
  },
];
